import { Vue, Component } from 'vue-property-decorator'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

@Component({
    'data': () => ({
        StepUnit
    })
})
export class InputSetups extends Vue {
    modelSetter(args: FieldData): FieldData {
        args.setter = (value: any) => {
            this.$set(args.model, args.key, value)
        }
        return args
    }
}
