import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'
import { EventStatisticRecord } from '@/includes/services/EventService'

import { cloneDeep, has } from 'lodash'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class EventStatisticsMixin extends Vue {

    prepareEventWeightStat(statistics: Array<EventStatisticRecord>, eventName: string, eventId: number, from, to): { name: string, data: Array<number> } {
        const { timeTicks, dataTicks } = fromToChartTicks(from, to)

        const name = this.$t('event_total_weight', [ eventName ]).toString();
        const data = dataTicks;

        statistics.forEach((record) => {
            if (record.event_id === eventId) {
                const matchIndex = timeTicks.indexOf(record.time)

                data[matchIndex] = record.weight
            }
        })

        return cloneDeep({
            name,
            data
        })
    }
}