





























import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class SaveConfigPanel extends Vue {
  @Prop() avatar!: string

  @Prop() avatarClickCallback!: Function

  @Prop({ type: String, default: 'save_settings', required: false }) saveButtonTooltipText!: string
}
