































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

@Component({
  components: {
    Tags
  }
})
export default class EventTriggerCard extends Vue {
  @Prop() trigger!: SelectOptionData

  gotoTrigger():void {
    this.$router.push({
      name: 'Trigger',
      params: {
        actionType: 'edit'
      },
      query: {
        triggerKey: this.trigger.value
      }
    })
  }
}
