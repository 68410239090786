import { cloneDeep } from 'lodash'
import moment from 'moment'

export function fromToChartTicks(fromDate: string, toDate: string): { timeTicks: Array<string>, dataTicks: Array<number> } {
    let timeTicks: Array<string> = []
    let dataTicks: Array<number> = []

    const from = moment(fromDate)
    const to = moment(toDate)

    while (!from.isAfter(to)) {
        timeTicks.push(from.format('YYYY-MM-DDTHH:mm'))
        dataTicks.push(0)
        from.add(1, 'day')
    }

    return cloneDeep({ timeTicks, dataTicks })
}
