import BaseApi from '@/includes/services/BaseApi'
import { User } from '@/includes/types/User'

export type EventStatisticRecord = {
    time: string
    event_id: number
    weight: number
}

export default class EventService {
    static async getEventsStat(type: string, body): Promise<{ items: Array<User & { stat?: Array<EventStatisticRecord> }> }> {
        return await BaseApi.sendRequest(type, 'geteventsstat', body)
    }
}