





















































































import { InputSetups } from '@/mixins/input-setups'
import SaveConfigPanel from '@/components/SaveConfigPanel.vue'
import EventService, { EventStatisticRecord } from '@/includes/services/EventService'
import EventCard from '@/components/EventCard.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import EventTriggerCard from '@/components/EventTriggerCard.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    EventTriggerCard,
    PageTitle,
    SaveConfigPanel,
    EventCard,
    EmptyData
  }
})
export default class Events extends Mixins<UseFields, InputSetups, DrawerWidthMixin>(UseFields, InputSetups, DrawerWidthMixin) {

  eventName = ''

  selectedEvent = ''

  isModalActive = false

  modalMode: 'new' | 'edit' = 'new'

  events: Record<string, number> = {}

  statistics: Array<EventStatisticRecord> | null = null

  dates = {
    from: moment().add(-7, 'd').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  triggersAboutOpen = false

  triggersInfo: Array<SelectOptionData> | null = null

  get title(): string {
    return this.modalMode === 'new' ? this.$t('add_event_main_popup_title').toString() : this.$t('update_event_main_popup_title').toString()
  }

  handleEditEventClick(eventKey: string): void {
    this.selectedEvent = eventKey
    this.eventName = eventKey

    this.togglePopup(true, 'edit')
  }

  handlePopupAcceptButtonClick(): void {
    let eventsCopy = cloneDeep(this.events)

    if (this.modalMode === 'new') {
      this.$set(eventsCopy, this.eventName, 0)
    }

    if (this.modalMode === 'edit') {
      eventsCopy = Object.fromEntries(
        Object.entries(eventsCopy)
          .map(([ key, value ]) => {
            if (key === this.selectedEvent) return [ this.eventName, value ]
            return [ key, value ]
          })
      )
    }

    this.updateConfigEvents(eventsCopy)
  }

  handleAddEventButtonClick(): void {
    this.togglePopup(true, 'new')
  }

  handleRemoveEventClick(key): void {
    this.$confirm({
      title: this.$t('remove_event_title').toString(),
      content: this.$t('remove_event_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        const eventsCopy = cloneDeep(this.events)
        delete eventsCopy[key]
        this.updateConfigEvents(eventsCopy)
      }
    })
  }

  updateConfigEvents(events): void {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$set(this.$store.state.userState.currentUserConfig?.config, 'events', events)
      }
    })

    this.$store.dispatch('SET_USER_CONFIG', this.$store.state.userState.currentUserConfig?.user.key)
      .then(successNotification)
      .then(() => this.togglePopup(false))
      .then(() => this.eventName = '')
      .then(() => this.events = cloneDeep(this.$store.state.userState.currentUserConfig!.config.events))
      .catch(errorNotification)
  }

  togglePopup(state: boolean, mode: 'new' | 'edit' = 'new'): void {
    this.modalMode = mode
    this.isModalActive = state
  }

  handleShowTriggers(triggers: Array<SelectOptionData>) {
    this.triggersInfo = triggers
    this.triggersAboutOpen = true
  }

  getStatistics(): void {
    EventService.getEventsStat('tg', { from: this.dates.from, to: this.dates.to })
      .then(({ items }) => {
        const user = items.find(u => u.key.split('.')[0] === this.$route.params.id)
        const events = this.$store.state.userState.currentUserConfig?.config.events

        if (user && !isEmpty(events)) {
          this.statistics = user?.stat ?? []
        }
      })
  }

  created(): void {
    if (!isEmpty(this.$store.state.userState.currentUserConfig.config.events)) {
      this.events = cloneDeep(this.$store.state.userState.currentUserConfig.config.events)
      this.getStatistics()
    }
  }
}
