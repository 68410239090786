var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAddEventButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('add_event_button'))+" ")])]},proxy:true}])}),_c('a-drawer',{attrs:{"visible":_vm.triggersAboutOpen,"width":_vm.drawerWidth,"mask-closable":""},on:{"close":function($event){_vm.triggersInfo = null; _vm.triggersAboutOpen = false}}},[_c('div',{staticClass:"event-trigger-card-list p-5"},_vm._l((_vm.triggersInfo),function(trigger){return _c('event-trigger-card',{key:trigger.value,attrs:{"trigger":trigger}})}),1)]),(Object.entries(_vm.events).length)?_c('a-row',{attrs:{"type":"flex","gutter":[16, 16]}},_vm._l((_vm.events),function(eventId,eventName){return _c('a-col',{key:eventId,attrs:{"xs":24,"sm":12,"md":12,"xl":8}},[_c('event-card',{attrs:{"event-name":eventName,"event-id":eventId,"statistics":_vm.statistics,"dates":_vm.dates},on:{"onEditIconClick":_vm.handleEditEventClick,"onRemoveIconClick":_vm.handleRemoveEventClick,"showTriggers":_vm.handleShowTriggers}})],1)}),1):_c('empty-data',{staticClass:"mt-5"}),_c('a-modal',{attrs:{"centered":"","title":_vm.title,"ok-text":_vm.$t('accept'),"cancel-text":_vm.$t('reject'),"ok-button-props":{
      props: {
        disabled: !_vm.eventName.trim().length
      }
    }},on:{"ok":_vm.handlePopupAcceptButtonClick,"cancel":function($event){_vm.eventName = ''}},model:{value:(_vm.isModalActive),callback:function ($$v) {_vm.isModalActive=$$v},expression:"isModalActive"}},[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'eventName'
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }