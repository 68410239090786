


















































import { EventStatisticRecord } from '@/includes/services/EventService'
import EventStatisticsMixin from '@/mixins/statistics/Events/EventStatisticsMixin'
import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'

@Component({
  components: {
    VueApexCharts
  },
})
export default class EventCard extends Mixins<EventStatisticsMixin>(EventStatisticsMixin) {

  @Prop({ type: String }) eventName!: string

  @Prop({ type: Number }) eventId!: number

  @Prop() statistics!: Array<EventStatisticRecord> | null

  @Prop() dates!: { from: string, to: string }

  @Emit('showTriggers')
  showTriggers(): Array<SelectOption> {
    return this.triggersWithEvent.sort(a => a.disabled ? 1 : -1)
  }

  get chart(): ApexChartSeries | null {
    if (this.statistics !== null) {
      return [ this.prepareEventWeightStat(this.statistics, this.eventName, this.eventId, this.dates.from, this.dates.to) ]
    }

    return null
  }

  get triggersWithEvent(): Array<SelectOptionData> {
    const triggers = this.$store.state.triggerState

    if (triggers.frontendTriggers) {
      const usageTriggers: Array<SelectOptionData> = []

      triggers.frontendTriggers.forEach(value => {
        const actionGroups = value.config.actions
        const findEventInTrigger = (group) => group.actions.some(action => action.type === 'RegisterEventAction' && this.eventId === action.eventId)

        if (actionGroups.some(findEventInTrigger)) {
          const isTriggerActive = triggers.activeTriggers.includes(Number(value.key.split('.')[0]))

          usageTriggers.push({
            label: value.config.name,
            value: value.key,
            disabled: !isTriggerActive,
            tags: isTriggerActive ?
              [ { color: 'rgba(var(--a-success))', text: 'Активный' } ] :
              [ { color: 'rgba(var(--a-danger))', text: 'Отключен' } ]
          })
        }
      })

      return usageTriggers
    }

    return []
  }

  chartOptions: ApexOptions = {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [ 0, 100 ],
      },
    },
    xaxis: {
      categories: fromToChartTicks(this.dates.from, this.dates.to).timeTicks.map(t => moment(t).format('LL')),
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '8px',
          fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => value.toFixed(0),
      },
    },
    tooltip: {
      x: { show: true },
    }
  }

  @Emit('onEditIconClick')
  onEditIconClick(eventName: string): string {
    return eventName
  }

  @Emit('onRemoveIconClick')
  onRemoveIconClick(eventName: string): string {
    return eventName
  }
}
